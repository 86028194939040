import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"

import ContactForm from "../components/footerContactBlock"
//import Faqs from "../components/faqs"


const PromoClothing = ({location, pageContext,data }) => {
  ///page details
  const pageDataEdges = pageContext;
  //const faqs = data.allFaqsJson.nodes[0];
  const testimonialData = data.allTestimonialshomeJson.edges;
  const date = new Date(pageContext.publishDate);
  const formattedDate = `${date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}`;

    const nodes = testimonialData.map((item) => { 
        return item.node;
    })
    
  return (
    <Layout>
      <Seo
        title={pageDataEdges.blogSEOTitle}
        description={pageDataEdges.blogSubTitle}
      />
        <TitleBlock
        pageTitle={pageDataEdges.blogTitle}
        pageTitleBody={pageDataEdges.blogSubTitle}
        date={formattedDate}
        img={pageDataEdges.blogImg}
        currentLocation = {location}
      />
      
      <div className="container">
        <div className="row py-5">
          <div className="col"
            data-sal-duration="500"
            data-sal="fade-up"
            data-sal-easing="ease"
          >
             {pageContext.blogSection !== null &&
            
              <>   
              {pageContext.blogSection.map((section, index) => ( 
                  
                  <>
                    { 
                      ////////////////////////////////////////////////////////////////////////////////
                      section.type === 'text' &&
                    <p key={index} dangerouslySetInnerHTML={{ __html: section.content }}/>
                  }
                  { 
                      ////////////////////////////////////////////////////////////////////////////////
                      section.type === 'title' &&
                    <h2 className="fw-light" key={index} dangerouslySetInnerHTML={{ __html: section.content }} />
                  }
                  { 
                      ////////////////////////////////////////////////////////////////////////////////
                    section.type === 'list' &&
                    
                    <ul>
                        {section.items.map((item, index) => (
                          <li key={index} dangerouslySetInnerHTML={{ __html: item.content }} />
                        ))}
                    </ul>
                      
                  }
                  { 
                      ////////////////////////////////////////////////////////////////////////////////
                      section.type === 'listOL' &&
                      <ol>
                        {section.items.map((item, index) => (
                          <li key={index}>{item.content}</li>
                        ))}
                    </ol>
                  }

                </>

                ))}
              
            </>
          }
            
          </div>
        </div>
      </div>
      
      <ContactForm   />

      

      

      
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    allTestimonialshomeJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }

    allFaqsJson {
        nodes {
        faqTitle
            faqs {
                content
                title
            }
        }
    }
}`
export default PromoClothing